@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600&display=swap');
@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components{
    .page-container{
        @apply container w-[100%] sm:w-full mx-0 min-w-full;
    }

    @media screen and (min-width: 640px) and (max-width: 1024px){
        .responsive-table tfoot{
            @apply block;
        }
    }
    
    @media screen and (max-width: 1024px){
        .responsive-table, .responsive-table tbody, .responsive-table tbody tr{
            @apply block;
        }

        .responsive-table tr:not(:last-of-type){
            @apply mb-4;
        }

        .responsive-table tbody tr td:not(:last-of-type){
            @apply border-b;
        }

        .responsive-tbody-cell{
            @apply flex justify-between items-center gap-2 before:content-[attr(data-label)];
        }

        .responsive-tfoot-cell{
            @apply flex justify-between items-center;
        }
    }

    .active{
        @apply bg-blue-600;
    }

    .sale-row.selected{
        @apply bg-sky-800 !important;
    }

    @media screen and (pointer: fine){
        tr.sale-hover{
            @apply hover:bg-gray-300;
        }
    }

    .auto-modal{
        @apply fixed top-0 left-0 w-full h-[100vh] bg-black/75 z-50;
    }

    .table-grid{
        @apply grid grid-rows-[var(--table-running-size)_calc(100vh-var(--table-running-size)*2)_var(--table-running-size)];
    }

    .custom-tooltip{
        @apply
            relative
            before:content-[attr(data-tooltip)]
            before:absolute
            before:left-0
            before:bg-gray-900/80
            before:whitespace-nowrap
            before:text-sm
            before:text-white
            before:font-medium
            before:py-1
            before:px-2
            before:rounded-sm
            before:opacity-0
            before:pointer-events-none
            before:transition-opacity
            hover:before:opacity-100
            hover:before:pointer-events-none
            before:z-30;
    }

    .custom-tooltip.bottom-tooltip{
        @apply before:top-[180%];
    }

    .custom-tooltip.top-tooltip{
        @apply before:bottom-[180%];
    }

    .send-button{
        @apply hidden sm:block rounded py-2 px-3 text-sm font-semibold;
    }

    .cancel-button{
        @apply hidden sm:block rounded py-2 px-3 text-sm font-semibold;
    }

    .loader-circle{
        @apply w-4 h-4 rounded-full bg-gray-100;
    }

    .list-styled li{
        @apply relative block py-[4em] pr-[4em] pl-[2em] bg-[#ddd] rounded-md text-[#444];
    }

    .form-button{
        @apply p-3 cursor-pointer bg-sky-600 uppercase tracking-wider text-sm font-medium;
    }

    .sale-card{
        @apply bg-[#ddd] rounded-md w-full py-2 px-4 flex flex-col gap-3 text-[#444];
    }
}

:root{
    --table-running-size: 4rem;
}

body > #root{
    display: grid;
    grid-template-rows: min-content auto min-content;
    min-height: 100vh;
}

html{
    font-size: 0.8125rem;
}

@media screen and (min-width: 20rem){
    html{
        font-size: calc(0.8125rem + 0.1875 * ((100vw - 20rem) / 61.25));
    }
}

@media screen and (min-width: 81.25rem){
    html{
        font-size: 1rem;
    }
}

@media screen and (pointer: fine){
    .table-size::-webkit-scrollbar{
        @apply w-[12px];
    }
    
    .table-size::-webkit-scrollbar-thumb{
        @apply bg-gray-900 rounded-md border-[3px] border-gray-800 border-solid;
    }
}

[type=checkbox]{
    --size: 1.35rem;
    -webkit-appearance: none;
    @apply 
        w-[var(--size)] 
        h-[var(--size)]
        ml-2 
        border-none
        outline-none
        grow-0
        rounded-full 
        align-middle 
        bg-white 
        transition-colors
        cursor-pointer;
}

[type=checkbox].green-check{
    @apply text-green-500;
}

[type=checkbox].blue-check{
    @apply text-[dodgerblue];
}

[type=checkbox]::before {
    content: "";
    color: transparent;
    display: block;
    width: inherit;
    height: inherit;
    border-radius: inherit;
    border: 0;
    background-color: transparent;
    @apply bg-contain bg-center bg-no-repeat;
}

[type=checkbox]:checked{
    @apply bg-current;
}

[type=checkbox]:checked::before {
    box-shadow: none;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24'%3E %3Cpath d='M15.88 8.29L10 14.17l-1.88-1.88a.996.996 0 1 0-1.41 1.41l2.59 2.59c.39.39 1.02.39 1.41 0L17.3 9.7a.996.996 0 0 0 0-1.41c-.39-.39-1.03-.39-1.42 0z' fill='%23fff'/%3E %3C/svg%3E");
}

@keyframes loader-animation {
    to {
        opacity: .1;
        transform: translate3d(0, 1rem, 0);
    }
}

.loader-circle{
    animation: loader-animation .6s infinite alternate;
}

.loader-circle:nth-child(2) {
    animation-delay: .2s;
}

.loader-circle:nth-child(3) {
    animation-delay: .4s;
}

.products-list::-webkit-scrollbar{
    --scroll-size: 6px;
    width: var(--scroll-size);
    height: var(--scroll-size);
}

.products-list::-webkit-scrollbar-track{
    @apply bg-transparent rounded-md;
}

.products-list::-webkit-scrollbar-thumb{
    @apply bg-gray-400 rounded-lg border-[2px] border-solid border-transparent;
}

[type=number]::-webkit-inner-spin-button, 
[type=number]::-webkit-outer-spin-button { 
    appearance: none; 
}
